import Sockette from "sockette";
import { useEffect, useState, type FunctionComponent } from "react";
import { useNavigate } from "react-router";

import AlertComponent from "./components/AlertComponent";
import "./dashboard.css";

export type Alert = {
    projectName: string;
    timestamp: number;
    accountId?: string;
    alarmArn?: string;
    alarmName?: string;
    alarmStatus?: string;
};

const Dashboard: FunctionComponent = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<Alert[]>([]);

    useEffect(() => {
        const webSocket = new Sockette(
            "wss://58pi80zjjf.execute-api.eu-central-1.amazonaws.com/prod/",
            {
                // When the component is mounted, a 'fetchAlerts' action is sent to the webSocket, which will return all entries currently in the alert database
                onopen: () => webSocket.send(JSON.stringify({ action: "fetchAlerts" })),
                onmessage: (e) => {
                    const receivedData = JSON.parse(e.data);
                    // When a message is received, it is either an object with a property called Items that contains an array (all alerts currently in the database)
                    if (receivedData.Items && Array.isArray(receivedData.Items)) {
                        setData((prevdata) => [...receivedData.Items, ...prevdata]);
                        // or an object without these things (an update of the alert database, sent via a dynamoDb stream)
                    } else {
                        setData((prevdata) => [receivedData, ...prevdata]);
                    }
                },
                onerror: (e) => console.error(e),
                onreconnect: () => setData([]),
                maxAttempts: 5,
            },
        );

        // Cleanup function to close the WebSocket connection when the component unmounts
        return () => {
            webSocket.close();
        };
    }, []);

    return (
        <div className="dashboard-container">
            <ul className="dashboard">
                {data.map((alert) => {
                    return (
                        <AlertComponent
                            projectName={alert.projectName}
                            accountId={alert.accountId}
                            alarmArn={alert.alarmArn}
                            alarmName={alert.alarmName}
                            alarmStatus={alert.alarmStatus}
                            timestamp={alert.timestamp}
                        />
                    );
                })}
            </ul>
            <button onClick={() => navigate("/")}>Close</button>
        </div>
    );
};

export default Dashboard;
