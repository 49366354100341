import type { FunctionComponent } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { RoutePath } from "./types";
import Dashboard from "./pages/dashboard/Dashboard";
import "app.css";

const App: FunctionComponent = () => {
    return (
        <div className="app">
            <BrowserRouter>
                <Routes>
                    <Route path={RoutePath.HOME}>
                        <Route path={RoutePath.HOME} element={<h1> Hello World!</h1>} />
                        <Route path={RoutePath.Dashboard} element={<Dashboard />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
