import type { FunctionComponent } from "react";

import type { Alert } from "../Dashboard";

import "../dashboard.css";

const AlertComponent: FunctionComponent<Alert> = ({
    projectName,
    accountId,
    alarmArn,
    alarmName,
    alarmStatus,
    timestamp,
}) => {
    const formattedDateTime = new Date(timestamp).toLocaleString("nl-NL");
    return (
        <li className="alert-component">
            Project: {projectName}. {accountId && `Account Id: ${accountId}.`}{" "}
            {alarmName &&
                `Alarm name:
            ${alarmName}.`}
            {alarmStatus && ` Alarm status: ${alarmStatus}.`}
            {alarmArn && ` Alarm arn: ${alarmArn}.`}
            {timestamp && ` Timestamp: ${formattedDateTime}.`}
        </li>
    );
};

export default AlertComponent;
